// @flow

import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { format } from 'date-fns';
import enLocale from 'date-fns/locale/en-US';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import CircularProgress from '@mui/material/CircularProgress';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDispatch, useSelector } from 'react-redux';
import { showToastMsg } from 'features/toast-message-slice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import MDPBackend from 'services/MDPBackend';
import { ForwardRef } from 'components';
import {
  useAddSourceToPatientHccMutation,
  useLazySearchCiInputsQuery,
  useGetBenchmarkEvidenceForPatientMutation
} from 'api/api';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  debounce,
  Typography,
  Switch,
  FormControlLabel,
  Divider,
  Box
} from '@mui/material';
import styled from 'styled-components';
import { LocalizationProvider } from '@mui/x-date-pickers';

type AddSourceModalProps = {
  open: Boolean,
  classes: Object,
  hccId: Array,
  patientId: String,
  handleClose: () => void
};

const AddSourceModal = (props: AddSourceModalProps) => {
  const currentOrganization = useSelector((state) => state.currentOrganization);
  const dispatch = useDispatch();
  const { open, handleClose, classes, hccId, patientId } = props;
  const [autocompleteKey, setAutocompleteKey] = useState(0);

  const [isBenchmarkView, setIsBenchmarkView] = useState(false);
  const [savedBenchmarkEvidence, setSavedBenchmarkEvidence] = useState([]);
  const [selectedBenchmarkEvidence, setSelectedBenchmarkEvidence] =
    useState('');
  const [isSearchingBenchmarkEvidence, setIsSearchingBenchmarkEvidence] =
    useState(false);
  const [benchmarkEvidence, setBenchmarkEvidence] = useState([]);
  const [benchmarkEvidenceSearchTerm, setBenchmarkEvidenceSearchTerm] =
    useState('');
  const [isBenchmarkEvidenceDropdownOpen, setIsBenchmarkEvidenceDropdownOpen] =
    useState(false);

  const [addSourceToPatientHcc, { isLoading }] =
    useAddSourceToPatientHccMutation();
  const [searchCiInputs, { data: inputOptions }] = useLazySearchCiInputsQuery();
  const [getBenchmarkEvidence, { data: benchmarkEvidenceForPatient }] =
    useGetBenchmarkEvidenceForPatientMutation();

  const handleCloseAddSourceDialog = () => {
    setBenchmarkEvidence([]);
    setBenchmarkEvidenceSearchTerm('');
    setSelectedBenchmarkEvidence([]);
    setSavedBenchmarkEvidence([]);
    handleClose();
  };

  useEffect(() => {
    if (benchmarkEvidenceForPatient) {
      console.log('Fetched benchmark evidence: ', benchmarkEvidenceForPatient);
      setBenchmarkEvidence(benchmarkEvidenceForPatient);
      setIsBenchmarkEvidenceDropdownOpen(true); // Force dropdown open after data is updated
      setAutocompleteKey((prevKey) => prevKey + 1);
    }
  }, [benchmarkEvidenceForPatient]);

  const createFilePath = (fileId, fileName, searchString) => {
    if (!fileId) return '';

    const cleanSearchString = searchString
      ? searchString.replace(/(\<em\>|\<\/em\>)/g, '')
      : '';

    const encodedSearchString = encodeURIComponent(cleanSearchString);
    const encodedFileName = fileName ? encodeURIComponent(fileName) : '';

    // eslint-disable-next-line max-len
    let path = `/viewer/${patientId}?f=${fileId}&q=${encodedSearchString}&title=${encodedFileName}`;

    return path;
  };

  const prepareSelectedBenchmarkEvidence = () => {
    return savedBenchmarkEvidence.map((ev) => {
      const clinicalIndicator = {
        type: ev.clinicalIndicator.type,
        name: ev.clinicalIndicator.name,
        measure: ev.clinicalIndicator.measure,
        unit: ev.clinicalIndicator.unit,
        operator: ev.clinicalIndicator.operator,
        value: ev.clinicalIndicator.value,
        date: ev.clinicalIndicator.date
      };

      const excerpt = {
        documentId: ev.excerpt.documentId ?? null,
        fileName: ev.excerpt.fileName,
        pageNb: ev.excerpt.page ?? null,
        sectionTitle: ev.excerpt.sectionTitle ?? null,
        hash: ev.excerpt.hash,
        contentType: ev.excerpt.contentType,
        excerpt: ev.excerpt.excerpt
      };

      const evidencePair = {
        clinicalIndicator: clinicalIndicator,
        excerpt: excerpt
      };

      return evidencePair;
    });
  };

  const getNewEvidenceObject = () => {
    const benchmarkEvidence = prepareSelectedBenchmarkEvidence();

    return {
      patientId: patientId,
      patientHccId: hccId,
      organizationId: currentOrganization._id,
      listOfEvidence: benchmarkEvidence,
      nlpSource: 'BENCHMARK'
    };
  };

  const handleSubmit = async () => {
    const body = {
      evidence: getNewEvidenceObject()
    };

    console.log('Body to submit: ', body);

    try {
      const response = await addSourceToPatientHcc({
        body: body
      }).unwrap();

      if (response.success) {
        dispatch(
          showToastMsg({
            open: true,
            message: 'Sources added successfully',
            level: 'success',
            duration: 5000
          })
        );

        handleClose();
      } else {
        dispatch(
          showToastMsg({
            open: true,
            message: 'Something went wrong!',
            level: 'error',
            duration: 5000
          })
        );
      }
    } catch (error) {
      dispatch(
        showToastMsg({
          open: true,
          message: error?.message ?? 'Adding source failed',
          level: 'error',
          duration: 5000
        })
      );
    } finally {
      handleCloseAddSourceDialog();
    }
  };

  const saveBenchmarkEvidence = () => {
    for (let ev of selectedBenchmarkEvidence) {
      const clinicalIndicator = {
        type: ev.type,
        name: ev.name,
        measure: ev.measure,
        unit: ev.unit,
        operator: ev.operator,
        value: ev.value,
        date: ev.date
      };

      const excerpt = {
        documentId: ev.documentId ?? null,
        fileName: ev.fileName,
        pageNb: ev.page ?? null,
        sectionTitle: ev.sectionTitle ?? null,
        hash: ev.hash,
        contentType: ev.contentType,
        excerpt: ev.excerpt
      };

      const evidencePair = {
        clinicalIndicator: clinicalIndicator,
        excerpt: excerpt
      };

      console.log('Saved benchmark evidence:', evidencePair);

      setSavedBenchmarkEvidence((prev) => [...prev, evidencePair]);
    }
  };

  // ----------- Benchmark Evidence ----------- //

  const handleBenchmarkEvidenceChange = (event) => {
    const value = event.target.value;

    setBenchmarkEvidenceSearchTerm(value);

    if (!value) {
      console.log('NO VALUE FOR BENCHMARK EVIDENCE SEARCH!');
      setBenchmarkEvidenceSearchTerm(null);
    } else {
      console.log('VALUE FOR BENCHMARK EVIDENCE SEARCH: ', value);

      debouncedBenchmarkEvidenceSearch(value);
    }
  };

  const handleSelectBenchmarkEvidence = (event) => {
    const selectedBenchmarkEvidenceId = event.target.value;
    const selectedBenchmarkEv = benchmarkEvidence.find(
      (ev) => ev._id === selectedBenchmarkEvidenceId
    );

    setSelectedBenchmarkEvidence(selectedBenchmarkEv);
  };
  const showBenchmarkEvidenceDetails = (evidence) => {
    return (
      <Box display="flex" flexDirection="column" p={2}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
          }}
        >
          <Typography
            variant="body2"
            color="textSecondary"
            sx={{
              marginRight: '5px'
            }}
          >
            <strong>CI Type: </strong>
            {evidence.type}
          </Typography>

          <Typography
            variant="body2"
            color="textSecondary"
            sx={{
              marginRight: '5px'
            }}
          >
            <strong>CI Name: </strong>
            {evidence.name}
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            sx={{
              marginRight: '5px'
            }}
          >
            <strong>Measure: </strong>
            {evidence.measure}
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            sx={{
              marginRight: '5px'
            }}
          >
            <strong>Unit: </strong>
            {evidence.unit}
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            sx={{
              marginRight: '5px'
            }}
          >
            <strong>Operator: </strong>
            {evidence.operator}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            <strong>Value: </strong>
            {evidence.value}
          </Typography>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant="body2" color="textSecondary">
            <strong>Excerpt: </strong>
            {evidence.excerpt}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            <strong>Filename: </strong>
            {evidence.fileName}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            <strong>Section: </strong>
            {evidence.sectionTitle}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            <strong>Date: </strong>
            {evidence.date}
          </Typography>
        </div>
      </Box>
    );
  };

  const showExcerptDetails = (option) => {
    return (
      <Box display="flex" flexDirection="column" p={2}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant="body2" color="textSecondary">
            <strong>Section: </strong>
            {option.section}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            <strong>Excerpt: </strong>
            {option.pageContent}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            <strong>Filename: </strong>
            {option.fileName}
          </Typography>
        </div>
      </Box>
    );
  };

  const handleDeleteBenchmarkEvidence = (index) => {
    setSavedBenchmarkEvidence((prev) => prev.filter((_, idx) => idx !== index));
  };

  const debouncedBenchmarkEvidenceSearch = useCallback(
    debounce(async (value) => {
      if (!value) {
        console.log(
          '[debouncedBenchmarkEvidenceSearch] Search field is empty, not searching.'
        );
        setBenchmarkEvidence([]);
        return;
      }

      console.log('[debouncedBenchmarkEvidenceSearch]  Searching for: ', value);

      setIsSearchingBenchmarkEvidence(true);
      try {
        const evidence = await getBenchmarkEvidence({
          patientId: patientId,
          searchTerm: value
        }).unwrap();

        if (!evidence) {
          dispatch(
            showToastMsg({
              open: true,
              message: 'No benchmark evidence found for patient',
              level: 'error',
              duration: 5000
            })
          );
        } else if (evidence && evidence.length === 0) {
          dispatch(
            showToastMsg({
              open: true,
              message: 'Did not find evidence matching your search',
              level: 'error',
              duration: 5000
            })
          );
        }
      } catch (error) {
        console.log('[debouncedBenchmarkEvidenceSearch] error: ', error);
        dispatch(
          showToastMsg({
            open: true,
            message: 'Error occured for this patient',
            level: 'error',
            duration: 5000
          })
        );
      } finally {
        setIsSearchingBenchmarkEvidence(false);
      }
    }, 1000),
    []
  );

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      className=""
      PaperProps={{
        className:
          '!min-w-[320px] md:!min-w-[768px] lg:!min-w-[1200px] !min-h-[700px] !max-w-full !w-full'
      }}
    >
      <DialogTitle className="!font-bold">Add Evidence</DialogTitle>
      <DialogContent>
        <Grid container spacing={3}>
          <div
            className="mt-4"
            style={{
              marginLeft: '15px',
              width: '100%'
            }}
          >
            <Grid item sm={12}>
              <>
                <Autocomplete
                  multiple
                  key={autocompleteKey}
                  open={isBenchmarkEvidenceDropdownOpen}
                  onOpen={() => setIsBenchmarkEvidenceDropdownOpen(true)}
                  onClose={() => setIsBenchmarkEvidenceDropdownOpen(false)}
                  options={benchmarkEvidence || []}
                  getOptionLabel={(option) => option.fileName}
                  isOptionEqualToValue={(option, value) =>
                    option._id === value._id
                  }
                  onInputChange={handleBenchmarkEvidenceChange}
                  onChange={(event, newValue) => {
                    console.log('Selected values: ', newValue);
                    setSelectedBenchmarkEvidence(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={`Search benchmark evidence`}
                      name="benchmark-evidence"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {isSearchingBenchmarkEvidence && (
                              <CircularProgress size={20} />
                            )}
                            {params.InputProps.endAdornment}
                          </>
                        )
                      }}
                    />
                  )}
                  renderOption={(props, option) => (
                    <li {...props} key={option._id}>
                      {showBenchmarkEvidenceDetails(option)}
                    </li>
                  )}
                />

                <Box mt={2}>
                  {selectedBenchmarkEvidence.length > 0 && (
                    <div>
                      {selectedBenchmarkEvidence.map((ev, index) => (
                        <Box key={index} mb={2}>
                          <Grid container spacing={2} alignItems="center">
                            <Grid item xs={8}>
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  justifyContent: 'space-between'
                                }}
                              >
                                <Typography
                                  variant="body2"
                                  sx={{
                                    marginRight: '5px'
                                  }}
                                >
                                  <strong>CI Type: </strong>
                                  {ev.type}
                                </Typography>

                                <Typography
                                  variant="body2"
                                  sx={{
                                    marginRight: '5px'
                                  }}
                                >
                                  <strong>CI Name: </strong>
                                  {ev.name}
                                </Typography>
                                <Typography
                                  variant="body2"
                                  sx={{
                                    marginRight: '5px'
                                  }}
                                >
                                  <strong>Measure: </strong>
                                  {ev.measure}
                                </Typography>
                                <Typography
                                  variant="body2"
                                  sx={{
                                    marginRight: '5px'
                                  }}
                                >
                                  <strong>Unit: </strong>
                                  {ev.unit}
                                </Typography>
                                <Typography
                                  variant="body2"
                                  sx={{
                                    marginRight: '5px'
                                  }}
                                >
                                  <strong>Operator: </strong>
                                  {ev.operator}
                                </Typography>
                                <Typography variant="body2">
                                  <strong>Value: </strong>
                                  {ev.value}
                                </Typography>
                              </div>

                              <Typography variant="body1">
                                <strong>Excerpt:</strong> {ev.excerpt}
                              </Typography>
                              <Typography variant="body1">
                                <strong>Filename:</strong> {ev.fileName}
                              </Typography>
                              {ev.sectionTitle && (
                                <Typography variant="body1">
                                  <strong>Section:</strong> {ev.sectionTitle}
                                </Typography>
                              )}

                              {ev.date && (
                                <Typography variant="body1">
                                  <strong>Date:</strong> {ev.date}
                                </Typography>
                              )}
                              <Button
                                variant="outlined"
                                className={classes.externalLinkBtn}
                                sx={{
                                  fontSize: '0.85rem'
                                }}
                                onClick={() => {
                                  window.open(
                                    createFilePath(
                                      ev.hash,
                                      ev.fileName,
                                      ev.excerpt
                                    ),
                                    '_blank'
                                  );
                                }}
                                target="_blank"
                              >
                                XML
                                <ForwardRef>
                                  <FontAwesomeIcon
                                    icon={solid('external-link')}
                                    className={classes.icon}
                                  />
                                </ForwardRef>
                              </Button>
                            </Grid>
                          </Grid>
                        </Box>
                      ))}
                    </div>
                  )}
                </Box>
                {selectedBenchmarkEvidence.length > 0 && (
                  <div className="flex">
                    <Button
                      variant="outlined"
                      className={classes.saveEvidenceBtn}
                      onClick={() => {
                        saveBenchmarkEvidence();
                        setSelectedBenchmarkEvidence([]);
                      }}
                    >
                      Save Evidence
                    </Button>
                  </div>
                )}
              </>
            </Grid>
            {savedBenchmarkEvidence.length > 0 && (
              <div
                style={{
                  marginBottom: '15px',
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%'
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    color: 'darkgreen',
                    marginBottom: '20px',
                    marginTop: '20px'
                  }}
                >
                  <strong>SAVED EVIDENCE</strong>
                </Typography>
                {savedBenchmarkEvidence.map((evidence, i) => (
                  <Grid item xs={12} key={i}>
                    <div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between'
                        }}
                      >
                        <Typography
                          variant="body2"
                          sx={{
                            marginRight: '5px'
                          }}
                        >
                          <strong>CI Type: </strong>
                          {evidence.clinicalIndicator.type}
                        </Typography>

                        <Typography
                          variant="body2"
                          sx={{
                            marginRight: '5px'
                          }}
                        >
                          <strong>CI Name: </strong>
                          {evidence.clinicalIndicator.name}
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{
                            marginRight: '5px'
                          }}
                        >
                          <strong>Measure: </strong>
                          {evidence.clinicalIndicator.measure}
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{
                            marginRight: '5px'
                          }}
                        >
                          <strong>Unit: </strong>
                          {evidence.clinicalIndicator.unit}
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{
                            marginRight: '5px'
                          }}
                        >
                          <strong>Operator: </strong>
                          {evidence.clinicalIndicator.operator}
                        </Typography>
                        <Typography variant="body2">
                          <strong>Value: </strong>
                          {evidence.clinicalIndicator.value}
                        </Typography>

                        <IconButton
                          onClick={() => handleDeleteBenchmarkEvidence(i)}
                        >
                          <DeleteIcon color="error" />
                        </IconButton>
                      </div>

                      <Typography variant="body1">
                        <strong>Excerpt:</strong> {evidence.excerpt.excerpt}
                      </Typography>
                      <Typography variant="body1">
                        <strong>Filename:</strong> {evidence.excerpt.fileName}
                      </Typography>
                      {evidence.excerpt.sectionTitle && (
                        <Typography variant="body1">
                          <strong>Section:</strong>{' '}
                          {evidence.excerpt.sectionTitle}
                        </Typography>
                      )}

                      {evidence.clinicalIndicator.date && (
                        <Typography variant="body1">
                          <strong>Date:</strong>{' '}
                          {evidence.clinicalIndicator.date}
                        </Typography>
                      )}

                      <Divider style={{ margin: '20px 0' }} />
                    </div>
                  </Grid>
                ))}
              </div>
            )}
          </div>
        </Grid>
      </DialogContent>
      <DialogActions className="flex !justify-end flex-row-reverse">
        <Button
          className=" !py-1 !text-black !text-[13px]"
          onClick={handleCloseAddSourceDialog}
        >
          Cancel
        </Button>

        <Button
          className="!py-1 !text-black !text-[13px]"
          sx={{
            ':not(:disabled)': {
              backgroundColor: 'rgba(76, 175, 80, 0.4)',
              '&:hover': {
                backgroundColor: 'rgba(76, 175, 80, 0.4)'
              }
            }
          }}
          variant="contained"
          onClick={handleSubmit}
          disabled={isLoading || savedBenchmarkEvidence.length === 0}
        >
          {isLoading ? 'Adding' : 'Confirm'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddSourceModal;
